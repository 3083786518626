import request from '@/router/axios';
import {businessUrl} from "@/config/env"

//新增
export const add = (row) => {
    return request({
        url : businessUrl + "qualityStandard/insertStandard",
        method: "post",
        data : {
            ...row
        }
    })
}

export const list = (row) => {
    return request({
        url: businessUrl + "qualityStandard/selectStandard",
        method: "post",
        data: {
            ...row
        }
    })
}

//根据标准code查询参数下拉接口
export const  parameterList = (row) => {
    return request({
        url: businessUrl + "qualityStandard/selectStandardXiaLa",
        method: "post",
        data: {
            ...row
        }
    })
}