import request from '@/router/axios';
import {businessUrl} from "@/config/env"

//新增
export const add = (row) => {
    return request({
        url : businessUrl + "qualityStandard/insertMaterial",
        method: "post",
        data : {
            ...row
        }
    })
};

export const  list = (row) => {
    return request({
        url: businessUrl + "qualityStandard/selectMaterialComparison",
        method: "post",
        data: {
            ...row
        }
    })
};

//查询人员信息接口
export const  selectAllUsers = () => {
    return request({
        url: businessUrl + "corpUsers/selectAll",
        method: "get",
    })
};

//根据品种ID，查询品种对应的批次号样本大小
export const  selectBatchNo = (productId) => {
    return request({
        url: businessUrl + "productBatch/selectBatch",
        method: "post",
        data: {
            productId
        }
    })
};

//查询物料名称编号
export const selectProduct = (row) => {
    return request({
        url : businessUrl + "product/select",
        method: "post",
        data : {
            ...row
        }
    })
};

//和上面list方法一样 重名
export const  selectMaterialComparison = (row) => {
    return request({
        url: businessUrl + "qualityStandard/selectMaterialComparison",
        method: "post",
        data: {
            ...row
        }
    })
};

//导出
export const  exportWuliao = (corpId) => {
    return request({
        url: businessUrl + "qualityStandard/export?corpId="+corpId,
        method: "get",
    })
};
