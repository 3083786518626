<!-- 视图 -->
<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{
                    $t('New material comparison')}}
                </div>
            </div>
            <template>
                <div>
                    <el-form :model="formObj" ref="refForm" :rules="formObjRules" label-position="top">
                        <!--表头名称-->
                        <el-divider content-position="left">{{ $t('STANDARD') }}</el-divider>
                        <el-row class="el-row_style">
                            <el-col :span="10" :sm="20" :md="10" class="el-col_style"
                                    style="display: flex; width: 100%">
                                <el-form-item :label="$t('standard number')" prop="standardCode" label-width="120px"
                                              style=" width: 20%">
                                    <el-select v-model="formObj.standardCode" filterable clearable placeholder="请选择"
                                               @change="changeOptions($event),changeXiaLa()">
                                        <el-option v-for="item in parameterStandardList" :key="item.standardCode"
                                                   :label="item.standardCode"
                                                   :value="{value:item.standardCode,standardCode:item.standardCode}">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('standard name')" prop="standardName" label-width="120px"
                                              style=" width: 20%" v-if="this.routeSign!=='update'">
                                    <el-select v-model="formObj.standardName" filterable clearable disabled
                                               placeholder="请选择">
                                        <el-option v-for="item in parameterStandardList" :key="item.standardName"
                                                   :label="item.standardName" :value="item.standardName">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('surveyor')" prop="inspector" label-width="120px"
                                              style=" width: 20%">
                                    <el-select v-model="formObj.inspector" filterable clearable placeholder="请选择"
                                               style="width: 220px">
                                        <el-option v-for="item in users" :key="item.name" :label="item.name"
                                                   :value="item.name"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="el-row_style">
                            <el-col :span="10" :sm="20" :md="10" class="el-col_style"
                                    style="display: flex; width: 100%">
                                <el-form-item :label="$t('Product number')" prop="productCode" label-width="120px"
                                              style=" width: 20%">
                                    <el-select v-model="formObj.productCode" filterable clearable placeholder="请选择"
                                              style="width: 220px"  @change="changeProduct($event),selectAllProduct()">
                                        <el-option v-for="item in productList" :key="item.code" :label="item.code"
                                                    :value="{value:item.code,productCode:item.code}">

                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('Product name')" prop="productName" label-width="120px"
                                              style=" width: 20%">
                                    <el-select v-model="formObj.productName" filterable clearable placeholder="请选择"
                                              style="width: 220px" disabled>
                                        <el-option v-for="item in productList" :key="item.name" :label="item.name"
                                                   :value="item.name"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('batch number')" prop="batchNo" label-width="120px"
                                              style=" width: 20%">
                                    <el-select v-model="formObj.batchNo" filterable clearable placeholder="请选择"
                                               style="width: 220px">
                                        <el-option v-for="item in batchNoList" :key="item.batchCode"
                                                   :label="item.batchCode" :value="item.batchCode"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :label="$t('sample size')" prop="sampleSize" label-width="120px"
                                              style=" width: 20%">
                                    <el-input v-model="formObj.sampleSize" filterable clearable placeholder="请选择"
                                              style="width: 220px"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <!--表体名称-->
                        <div class="smallTit">{{ $t('Material Information') }}</div>
                        <el-table :data="formObj.itemTable" :row-class-name="tableRowClassName"
                                  style="width: 100%" :header-cell-class-name="starAdd">
                            <el-table-column :label="$t('Index')" type="index" width="50px" align='center'>
                            </el-table-column>
                            <el-table-column :label="$t('parameter number')" align='center'>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.parameterCode'">
                                        <el-input v-model="scope.row.parameterCode" clearable
                                                  :disabled="true"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('parameter name')" align='center' v-if="this.routeSign!=='update'">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.parameterName'">
                                        <el-input v-model="scope.row.parameterName" clearable
                                                  :disabled="true"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('Parameters of the standard')" align='center'>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.parameterStandard'">
                                        <el-input v-model="scope.row.parameterStandard" clearable laceholder=""
                                                  :disabled="true">
                                        </el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <!--默认当前时间-->
                            <el-table-column :label="$t('inspection data')" align='center'>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.inspectionMaterial'">
                                        <el-input v-model="scope.row.inspectionMaterial" clearable
                                                  @change="verification(scope.row.parameterStandard,scope.row.inspectionMaterial,scope.row.complianceWithStandards,scope.row.specificStandards, scope.row.index)">
                                        </el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('Manually entering parameters')" align='center' v-if="false">
                                <template slot-scope="scope">
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.specificStandards'">
                                        <el-input v-model="scope.row.specificStandards" clearable>
                                        </el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column :label="$t('Meet the standards')" align='center'>
                                <template slot-scope="scope">
                                    <el-form-item :prop="'itemTable.' + scope.$index + '.complianceWithStandards'">
                                        <el-select v-model="scope.row.complianceWithStandards" clearable
                                                   :disabled="scope.row.specificStandards.length === 0">
                                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align:right;padding:50px 0;">
                            <el-button type="primary" @click="submitForm" v-if="this.routeSign!=='update'">{{$t('Save') }}
                            </el-button>
                            <el-button @click="resetForm">{{ $t('Cancel') }}</el-button>
                        </div>
                    </el-form>
                </div>
            </template>
        </basic-container>
    </div>
</template>
<script>
    //引入对象
    import {list, parameterList} from "@/api/parameterStandard";
    import {add, selectAllUsers, selectBatchNo,selectProduct,selectMaterialComparison} from "@/api/materialComparison";

    export default {
        feedbackData: [],
        //data：返回实体对象
        data() {
            return {
                parameterStandardList: [],
                users: [],
                productList:[],
                batchNoList: [],
                //路由传递信息
                routeSign: '', routeId: '', productCode: '', productName: '', batchNo: '', sampleSize: '',
                //表单信息
                formObj: {
                    //表头信息
                    standardName: '', standardCode: '', productCode: '', productName: '', batchNo: '', sampleSize: '',
                    //表体信息
                    itemTable: [],
                },
                formObjRules: {
                    standardName: [{required: true, message: '请输入标准名称', trigger: 'blur'}],
                    standardCode: [{required: true, message: '请输入标准编号', trigger: 'blur'}],
                    productCode: [{required: true, message: '请输入产品物料编号', trigger: 'blur'}],
                },
                options: [{
                    value: '1',
                    label: '合格'
                }, {
                    value: '2',
                    label: '不合格'
                }],
            }
        },
        computed: {
            setData() {
                return {}
            }
        },
        created() {
            this.routeSign = this.$route.query.sign;
            if (this.routeSign === 'update'){
                this.edit();
            }else {
                this.xiala();
                this.init();
                this.selectAllUser();
                this.selectBatchNo();
                this.selectAllProduct();
            }
        },
        watch: {
            $route() {
                this.init()
            }
        },
        methods: {
            //初始加载数据
            init() {
                this.routeSign = this.$route.query.sign;
                this.formObj.productCode = this.$route.query.productCode;
                this.formObj.productName = this.$route.query.productName;
                this.formObj.bomNo = this.$route.query.bomNo;
                this.formObj.sampleSize = this.$route.query.sampleSize;
            },
            addrow() {
                let item = {
                    Id: "",
                    productCode: "",
                    productName: "",
                    batchNo: "",
                    inspector: "",
                    sampleSize: "",
                    standardCode: "",
                    complianceWithStandards: "",
                    inspectionMaterial: "",
                    parameterCode: "",
                    parameterStandard: "",
                };
                this.formObj.itemTable.push(item);
            },
            //提交数据
            submitForm() {
                this.$nextTick(() => {//避免点击两次
                    let formName = "refForm";
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            //新增
                            console.log("this.formObj", this.formObj);
                            var deetailList = [];
                            this.formObj.itemTable.forEach(v => {
                                let item = {
                                    "complianceWithStandards": v.complianceWithStandards === "合格" ? 1 : 2 ,
                                    "inspectionMaterial": v.inspectionMaterial,
                                    "parameterCode": v.parameterCode,
                                    "parameterStandard": v.parameterStandard,
                                };
                                this.formObj.itemTable.push(item);
                                deetailList.push(item);
                            });
                            var info = {
                                productCode: this.formObj.productCode,
                                productName: this.formObj.productName,
                                batchNo: this.formObj.batchNo,
                                inspector: this.formObj.inspector,
                                sampleSize: this.formObj.sampleSize,
                                standardCode: this.formObj.standardCode,
                                materialParameterStandardDtos: deetailList,
                            };
                            debugger
                            add(info).then((res) => {
                                this.request.post(res);
                                this.$message({
                                    type: "success",
                                    message: res.data.msg,
                                });
                                this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value === this.$route.fullPath), 1);
                                this.$router.push({path: '/quality/materialComparison'});
                            })
                        } else {
                            return false;
                        }
                    });
                });
            },
            //取消按钮
            resetForm() {
                this.$store.getters.tagList.splice(this.$store.getters.tagList.findIndex(item => item.value === this.$route.fullPath), 1);
                this.$router.push({path: '/quality/materialComparison'});
            },
            //添加行号
            tableRowClassName({row, rowIndex}) {
                // 把每一行的索引放进row
                row.index = rowIndex
            },
            //列头加星号
            starAdd(obj) {
                if (obj.columnIndex === 2 || obj.columnIndex === 3 || obj.columnIndex === 7 || obj.columnIndex === 8 || obj.columnIndex === 9 || obj.columnIndex === 11) {
                    return 'star';
                }
            },
            xiala() {
                const xx = {
                    pageNo: 1,
                    pageSize: 100,
                    total: 100,
                };
                list(xx).then(res => {
                    console.log(66, res);
                    this.parameterStandardList = res.data.data.items;
                });
            },
            //级联
            changeOptions(params) {
                const {standardCode} = params;
                this.parameterStandardList.forEach(item => {
                    if (item.standardCode === standardCode) {
                        console.log("级联", item.standardCode === standardCode);
                        this.formObj.standardName = item.standardName
                        this.formObj.standardCode = standardCode
                    }
                })
            },
            changeXiaLa() {
                const xx = {
                    pageNo: 1,
                    pageSize: 100,
                    total: 100,
                    standardCode: this.formObj.standardCode
                }
                parameterList(xx).then(res => {
                    this.formObj.itemTable = [];
                    res.data.data.forEach(v => {
                        let item = {
                            Id: "",
                            productCode: "",
                            batchNo: "",
                            sampleSize: "",
                            productName: "",
                            parameterStandard: v.minValue === null && v.maxValue === null ? v.specificStandards === null ? "" : v.specificStandards : v.maxValue + "-" + v.minValue,
                            inspector: "",
                            inspectionMaterial: "",
                            complianceWithStandards: "",
                            parameterCode: v.code,
                            parameterName: v.name,
                            specificStandards: v.specificStandards
                        }
                        this.formObj.itemTable.push(item);
                    })
                })
            },
            //产品物料级联
            changeProduct(params) {
                const {productCode} = params;
                this.productList.forEach(item => {
                    if (item.code === productCode) {
                        console.log("级联", item.code === productCode);
                        this.formObj.productName = item.name
                        this.formObj.productCode = productCode
                    }
                })
            },
            //查询人员信息
            selectAllUser() {
                selectAllUsers().then(res => {
                    console.log("查询人员信息", res);
                    this.users = res.data.data;
                });
            },
            //查询物料信息
            selectAllProduct() {
                selectProduct().then(res => {
                    console.log("查询物料信息", res);
                    this.productList = res.data.data;
                });
            },
            //根据品种ID，查询品种对应的批次号样本大小
            selectBatchNo() {
                this.routeSign = this.$route.query.sign;
                this.productId = this.$route.query.productId;
                selectBatchNo(this.productId).then(res => {
                    console.log("查询批次号", res);
                    this.batchNoList = res.data.data;
                });
            },
            //实时获取是否合格
            verification(parameterStandard, inspectionMaterial, complianceWithStandards, specificStandards, index) {
                console.log("参数标准" + parameterStandard);
                console.log("检验数据" + inspectionMaterial);
                console.log("是否符合标准" + complianceWithStandards);
                console.log("手动输入参数" + specificStandards);
                //手动输入参数的长度为0的时候 检验数据为数值的时候
                if (specificStandards.length === 0) {
                    let indexRule = parameterStandard.split('-');
                    console.log("截取字符串", indexRule);
                    console.log("最大值", indexRule[0], "最小值", indexRule[1])
                    //判断检验数据是否在参数标准中间
                    if (indexRule[0] < inspectionMaterial) {//检验数据大于最大值 不合格
                        this.formObj.itemTable[index].complianceWithStandards = "不合格";
                        debugger
                    } else if (indexRule[0] === inspectionMaterial) {//等于最大值 合格
                        this.formObj.itemTable[index].complianceWithStandards = "合格"
                    } else if (indexRule[0] > inspectionMaterial) {//小于最大值
                        if (indexRule[1] > inspectionMaterial) {//检验数据 小于最小值 不合格
                            this.formObj.itemTable[index].complianceWithStandards = "不合格";
                        } else if (indexRule[1] === inspectionMaterial) {//等于最小值 合格
                            this.formObj.itemTable[index].complianceWithStandards = "合格";
                        } else if (indexRule[1] < inspectionMaterial) {//大于最小值 合格
                            this.formObj.itemTable[index].complianceWithStandards = "合格";
                        }
                    }
                }
            },
            //查看
            edit(){
                this.routeSign = this.$route.query.sign;
                this.routeCode = this.$route.query.productCode;
                const xx = {
                    pageNo: 1,
                    pageSize: 100,
                    total: 100,
                    productCode: this.routeCode
                };
                console.log('xx', xx);
                selectMaterialComparison(xx).then(res => {
                    console.log('11111111111111111111111111111',res.data.data);
                    const info = res.data.data.items;
                    this.formObj.standardName = info[0].standardName;
                    this.formObj.standardCode = info[0].standardCode;
                    this.formObj.batchNo = info[0].batchNo;
                    this.formObj.sampleSize = info[0].sampleSize;
                    this.formObj.inspector = info[0].inspector;
                    this.formObj.productName = info[0].productName;
                    this.formObj.productCode = info[0].productCode;
                    this.formObj.itemTable =info[0].productCode;
                    console.log('211111111111111111111111111111',info);
                        this.formObj.itemTable = info.map((v) => {
                        return {
                            parameterCode:v.parameterCode,
                            inspectionMaterial: v.inspectionMaterial,
                            parameterStandard: v.parameterStandard,
                            complianceWithStandards: v.complianceWithStandards === null ? "" : v.complianceWithStandards === 1 ? "合格" : "不合格",
                            specificStandards:"",
                        }
                    });
                    console.log('311111111111111111111111111111',this.formObj)
                })
            },
        }
    }
</script>